import { MANDATORY_WEIGHT_SUCCESS, MANDATORY_WEIGHT_REQUEST, MANDATORY_WEIGHT_FAIL } from "../../../Constant/ApaManagement/MandatoryWeight/MandatoryWeightConstant";

 

export const getMandatoryWeightReducer = (state = { mandatoryWeight: [] }, action) => {

    switch (action.type) {

        case MANDATORY_WEIGHT_REQUEST:
            return {
                ...state,
                loading: true,

            }

        case MANDATORY_WEIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                mandatoryWeight: action.payload
            }

        case MANDATORY_WEIGHT_FAIL:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }


};
