import { SDG_Table_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/SDGTable/SDGTableConstant";

 

export const SdgTableReducer = (state = { sdgTableData: [] }, action) => {

    switch (action.type) {
        case SDG_Table_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                sdgTableData: action.payload


            }

        default:
            return state
    }


};