import { PUBLICATION_SUCCESS } from "../../Constant/Publication/PublicationConstant";

export const getPublicationReducer = (state = { publication: [] }, action) => {
  switch (action.type) {
    case PUBLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        publication: action.payload,
      };

    default:
      return state;
  }
};
