import { USER_DETAILS_SUCCESS } from "./UserDetailsConstant";



export const UserDetailsReducer = (state = { userDetailsData: {} }, action) => {

    switch (action.type) {
        case USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetailsData: action.payload,
            }
        default:
            return state;
    }

}



