import { PUBLICATION_LANDING_SUCCESS } from "../../Constant/Publication/PublicationLandingConstant";

export const getPublicationLandingReducer = (state = { publicationLanding: [] }, action) => {
  switch (action.type) {
    case PUBLICATION_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        publicationLanding: action.payload,
      };

    default:
      return state;
  }
};
