import { APA_DETERMINATION_TIME_SUBMISSION_SUCCESS } from "../../Constant/APADeterminationTimeSubmission/APADeterminationTimeSubmissionConstant";

  

export const getAPADeterminationTimeSubmissionReducer = (state = { apaDeterminationTimeSubmission: [] }, action) => {

    switch (action.type) {
        case APA_DETERMINATION_TIME_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                apaDeterminationTimeSubmission: action.payload
            }

        default:
            return state
    }


};
