import { GENERALTABLECOLUMNSALL_SUCCESS } from "../../Constant/ApaManagement/GeneralTableColumnsAllConstant ";


export const getGeneralTableColumnsAllReducer = (
  state = { generaltablecolumnsall: [] },
  action
) => {
   
  switch (action.type) {
    case GENERALTABLECOLUMNSALL_SUCCESS:
      return {
        ...state,
        loading: false,
        generaltablecolumnsall: action.payload,
      };

    default:
      return state;
  }
};
