import { ACRONYMS_REQUEST_SUCCESS } from "../../Constant/Configuration/AcronymsConstan";


export const getAcronymsReducer = (state = { acronyms: [] }, action) => {

    switch (action.type) {
        case ACRONYMS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                acronyms: action.payload


            }

        default:
            return state
    }


};
