import { USER_INPUT_PREVIEW_LINK_TABLE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/LinkTable/UserInputPreviewLinkTableConstant";


export const getUserInputPreviewLinkTableReducer = (state = { userinputlinktable: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_PREVIEW_LINK_TABLE_SUCCESS :
            return {
                ...state,
                loading: false,
                userinputlinktable: action.payload


            }

        default:
            return state
    }


};