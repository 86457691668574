import { ACTIVITY_CATEGORIES_LINK_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/Categories/ActivityCategoriesRelatedLink/ActivityCategoriesRelatedLinkConstant";

 

export const getActivityCategorieLinkReducer = (state = { allLinkedData: [] }, action) => {

    switch (action.type) {
        case ACTIVITY_CATEGORIES_LINK_TYPE_SUCCESS:
            return {
                ...state, 
                allLinkedData: action.payload


            }

        default:
            return state
    }


};
