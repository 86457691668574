import { CHECKER_SUBMISSION_SUCCESS } from "../../../Constant/ApaManagement/CheckerSubmission/CheckerSubmissionConstant";

 
export const getCheckerSubmissionDataReducer = (state = { checkerSubmissionData: {} }, action) => {

    switch (action.type) {
        case CHECKER_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                checkerSubmissionData: action.payload


            }

        default:
            return state
    }


};
