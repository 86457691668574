import {  ACTIVITY_CATEGORIES_DEVIDER_ALL_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/Categories/ActivityCategoriesDeviderAll/ActivityCategoriesDeviderAllConstant";

  

export const getActivityCategoriesDeviderALLReducer = (state = { activityCategoriesDeviderALL: [] }, action) => {

    switch (action.type) {
        case ACTIVITY_CATEGORIES_DEVIDER_ALL_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                activityCategoriesDeviderALL: action.payload


            }

        default:
            return state
    }


};
