import { CONTENT_MANAGEMENT_SUCCESS } from "./ContentManagementConstant";

 
export const ContentManagementReducer = (state = { contentData: [] }, action) => {

    switch (action.type) {
        case CONTENT_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                contentData: action.payload,
            }
        default:
            return state;
    }

}



