import { DEVIDER_DETAILS_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/Categories/ActivityCategoriesDeviderAll/DeviderDetailsConstant";


export const getDeviderDetailsReducer = (state = { deviderDetails: [] }, action) => {

    switch (action.type) {
        case DEVIDER_DETAILS_TYPE_SUCCESS:
            return {
                ...state,
                deviderDetails: action.payload


            }

        default:
            return state
    }


};
