import { APA_ACHIEVEMENT_SUBMISSION_TYPE_SUCCESS } from "../../Constant/ApaAchievement/ApaAchievementSubmissionTypeConstant";

  

export const getApaAchievementSubmissionTypeReducer = (state = { apaAchievementSubmissionType: [] }, action) => {

    switch (action.type) {
        case APA_ACHIEVEMENT_SUBMISSION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaAchievementSubmissionType: action.payload
            }

        default:
            return state
    }


};
