import { PREAMBLE_INPUTS_ALL_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/Preamble/PreambleInputAllConstant";

export const getPreambleAllByFilterInputsReducer = (state = { preambleFilterInputAll: [] }, action) => {
    switch (action.type) {
        case PREAMBLE_INPUTS_ALL_TYPE_SUCCESS:
            return {
                ...state,
                preambleFilterInputAll: action.payload
            }



        default:
            return state;
    }
}




