import { GENERAL_TEXTBOX_SUBTITLE_INPUTS_ALL_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTextBox/GeneralTextBoxSubtitleAllConstant";


export const getGeneralTextBoxSubtitlesInputsAllReducer = (state = { SubtitlesInputsAll: [] }, action) => {
    switch (action.type) {
        case GENERAL_TEXTBOX_SUBTITLE_INPUTS_ALL_TYPE_SUCCESS:
            return {
                ...state,
                SubtitlesInputsAll: action.payload
            }



        default:
            return state;
    }
}




