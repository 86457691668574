import { APA_EXPART_POOL_SUCCESS } from "../../Constant/APAExpertPool/APAExpertPoolConstant";

  

export const getAPAExpertPoolReducer = (state = { apaExpertPool: [] }, action) => {

    switch (action.type) {
        case APA_EXPART_POOL_SUCCESS:
            return {
                ...state,
                loading: false,
                apaExpertPool: action.payload
            }

        default:
            return state
    }


};
