import { MAKER_SUBMISSION_SUCCESS } from "../../../Constant/ApaManagement/MakerSubmission/MakerSubmissionConstant";


export const getMakerSubmissionDataReducer = (state = { makerSubmissionData: {} }, action) => {

    switch (action.type) {
        case MAKER_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                makerSubmissionData: action.payload

            }

        default:
            return state
    }


};
