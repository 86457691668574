import { CONFIGURABLE_PROFILE_SUCCESS } from "./ConfigurableProfileConstant";

 
export const getAllProfileReducer = (state = { profileData: [] }, action) => {
    switch (action.type) {
        case CONFIGURABLE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profileData: action.payload,
            };

        default:
            return state;
    }
};
