
import { OVERALLPERFORMANCESALLSUBTITLE_SUCCESS } from "../../Constant/ApaManagement/OverallPerformancessubtitleAllConstant ";



export const getOverallPerformancessubtitleAllReducer = (state = { overallperformancesubtitleall: [] }, action) => {

    switch (action.type) {
        case OVERALLPERFORMANCESALLSUBTITLE_SUCCESS:
            return {
                ...state,
                loading: false,
                overallperformancesubtitleall: action.payload
            }

        default:
            return state
    }


};
