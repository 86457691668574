 
import { TOOLKIT_SUCCESS } from "../../Constant/Configuration/ToolkitConstant";



export const getToolkitReducer = (state = { toolkitData: [] }, action) => {

    switch (action.type) {
        case TOOLKIT_SUCCESS:
            return {
                ...state,
                loading: false,
                toolkitData: action.payload


            }

        default:
            return state
    }


};
