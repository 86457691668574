import { GENERAL_TEXTBOX_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTextBox/GeneralTextBoxConstant";


export const getGeneralTextBoxSubtitlesReducer = (state = { SubtitlesAll: [] }, action) => {
    switch (action.type) {
        case GENERAL_TEXTBOX_TYPE_SUCCESS:
            return {
                ...state,
                SubtitlesAll: action.payload
            }



        default:
            return state;
    }
}




