import { DASHBOARD_INDICATOR } from "../../Constant/DashboardIndicator/DashboardIndicatorConstant";

export const DashboardIndicatorReducer = (
  state = { indicator: "" },
  action
) => {
  switch (action.type) {
    case DASHBOARD_INDICATOR:
      return {
        ...state,
        indicator: action.payload,
      };
    default:
      return state;
  }
};
