import { NOTICE_SUCCESS } from "../../../Constant/Notice/Notice/NoticeConstant";

export const getNoticeAddReducer = (state = { notice: [] }, action) => {
  switch (action.type) {
    case NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        notice: action.payload,
      };

    default:
      return state;
  }
};
