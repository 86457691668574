import { APA_CORRECTION_STATUS_SUCCESS } from "./ApaCorrectionTimeSettingConstant";

 
export const ApaCorrectionTimeSettingReducer = (state = { correctionStatusData: [] }, action) => {

    switch (action.type) {
        case APA_CORRECTION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                correctionStatusData: action.payload,
            }
        default:
            return state;
    }

}



