import { FAQ_REQUEST_SUCCESS } from "../../Constant/Configuration/FAQConstan";


export const getFAQReducer = (state = { faq: [] }, action) => {

    switch (action.type) {
        case FAQ_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                faq: action.payload


            }

        default:
            return state
    }


};
