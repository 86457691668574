import { FORMINPUTSALL_SUCCESS } from "../../Constant/FormInputs/FormInputsAllConstant";


export const getFormInputsAllReducer = (
  state = { formInputsAll: [] },
  action
) => {

  switch (action.type) {
    case FORMINPUTSALL_SUCCESS:
      return {
        ...state,
        loading: false,
        formInputsAll: action.payload,
      };

    default:
      return state;
  }
};
