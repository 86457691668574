import { ALL_SUCCESS } from "../../Constant/MasterData/GetAllConstant";


export const getAllReducer= (
  state = { masterAll: [] },
  action
) => {

  switch (action.type) {
    case ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        masterAll: action.payload,
      };

    default:
      return state;
  }
};
