import { APA_RELATED_TRANING_SUCCESS } from "../../Constant/APARelatedTraning/APARelatedTraningConstant";

  

export const getAPARelatedTraningReducer = (state = { apaRelatedTraning: [] }, action) => {

    switch (action.type) {
        case APA_RELATED_TRANING_SUCCESS:
            return {
                ...state,
                loading: false,
                apaRelatedTraning: action.payload
            }

        default:
            return state
    }


};
