import { APA_STRUCTURE_TYPE_SUCCESS } from "../../Constant/ApaManagement/ApaStructureConstant";

 

export const getApaStructureReducer = (state = { apaStructure: [] }, action) => {

    switch (action.type) {
        case APA_STRUCTURE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaStructure: action.payload


            }

        default:
            return state
    }


};
