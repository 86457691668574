
import {
    ADMIN_STRUCTURE_ADD_SECTION,
} from "../../Constant/AdminStructure/AdminStructure";


export const addReducerFirstSection = (state ={ test:[]}, action) => {

   

    switch (action.type) {
        case ADMIN_STRUCTURE_ADD_SECTION:
            return {
                ...state,
                test: [...state.test, action.payload],

            };
        default:
            return state;

    }

}