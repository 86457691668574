import { HIERARCHY_FAIL, HIERARCHY_REQUEST, HIERARCHY_SUCCESS } from "../../Constant/Configuration/HierarchyConstant";

export const getHierarchyReducer =(state={hierarchy:[]},action)=>{
 
    switch(action.type){

        case HIERARCHY_SUCCESS:
        return{
            ...state,
            hierarchy:action.payload
        }
        default:
            return state

    }



}