import { GENERAL_TABLE_SECOND_COL_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTable/GeneralTableSecondColConstant";

 

export const getGeneralTableSecondColReducer = (state = { generalTableSecondCol: [] }, action) => {

    switch (action.type) {
        case GENERAL_TABLE_SECOND_COL_SUCCESS:
            return {
                ...state,
                loading: false,
                generalTableSecondCol: action.payload


            }

        default:
            return state
    }


};