import { MANDATORY_DATA_SUCCESS } from "../../../Constant/ApaManagement/MandatoryWeight/MandatoryDataConstant";



export const getMandatoryDataReducer = (state = { mandatoryData: [] }, action) => {

    switch (action.type) {
        case MANDATORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                mandatoryData: action.payload


            }

        default:
            return state
    }


};
