import { WORKPLANMASTERDATASALL_SUCCESS } from "../../Constant/ApaManagement/WorkplanMasterDataAllConstant";


export const getWorkplanMasterDataAllReducer = (
  state = { workplanmasterdatasall: [] },
  action
) => {
  switch (action.type) {
    case WORKPLANMASTERDATASALL_SUCCESS:
      return {
        ...state,
        loading: false,
        workplanmasterdatasall: action.payload,
      };

    default:
      return state;
  }
};
