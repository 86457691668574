import { ALL_APA_STRUCTURE_TYPE_SUCCESS } from "../../Constant/ApaManagement/AllApaStructureConstant";

 

export const getALLApaStructureReducer = (state = { allStructure: [] }, action) => {

    switch (action.type) {
        case ALL_APA_STRUCTURE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                allStructure: action.payload


            }

        default:
            return state
    }


};
