import { NIS_SUCCESS } from "./NisConstant"; 
export const NISInfoReducer = (state = { NisData: [] }, action) => {

    switch (action.type) {
        case NIS_SUCCESS:
            return {
                ...state,
                loading: false,
                NisData: action.payload,
            }
        default:
            return state;
    }

}



