import { SHOW_COMMENT_SUCCESS } from "../../../Constant/ApaManagement/MakerSubmission/ShowCommentsConstant";


export const getShowCommentsDataReducer = (state = { showCommentsData: {} }, action) => {

    switch (action.type) {
        case SHOW_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                showCommentsData: action.payload

            }

        default:
            return state
    }


};
