import { GENERAL_TABLE_FIRST_COL_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTable/GeneralTableFirstColConstant";
 

export const getGeneralTableFirstColReducer = (state = { generalTableFirstCol: [] }, action) => {

    switch (action.type) {
        case GENERAL_TABLE_FIRST_COL_SUCCESS:
            return {
                ...state,
                loading: false,
                generalTableFirstCol: action.payload


            }

        default:
            return state
    }


};