import { FYMW_SUCCESS } from "../../Constant/Global/GetFyMwConstant";



export const GetFymwALLReducer = (state = { fymwData: {} }, action) => {

    switch (action.type) {
        case FYMW_SUCCESS:
            return {
                ...state,
                loading: false,
                fymwData: action.payload


            }

        default:
            return state
    }


};
