
import { SECTION_TYPE_SUCCESS } from "../../Constant/ApaManagement/SectionConstant";



export const getSectionReducer = (state = { section: [] }, action) => {

    switch (action.type) {
        case SECTION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                section: action.payload


            }

        default:
            return state
    }


};
