import { BUDGET_LINKAGE_SUCCESS } from "./BudgetLinkageConstant";



export const budgetLinkageReducer = (state = { budgetLinkageData: [] }, action) => {

    switch (action.type) {
        case BUDGET_LINKAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                budgetLinkageData: action.payload,
            }
        default:
            return state;
    }

}



