import { OVERALL_PERFORMANCE_SUBTITLE_INPUT_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/OverAllPerformance/OverAllPerformanceSubtitleInputConstant";
export const getOverAllSubtitleInputReducer = (state = { OverAllSubtitleInput: [] }, action) => {
    switch (action.type) {
        case OVERALL_PERFORMANCE_SUBTITLE_INPUT_SUCCESS:
            return {
                ...state,
                OverAllSubtitleInput: action.payload
            }



        default:
            return state;
    }

}