import { USER_INPUT_PREVIEW_GENERAL_TABLE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTable/UserInputPreviewGeneralTableConstant";


export const getUserInputPreviewGeneralTableReducer = (state = { userinputgeneraltable: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_PREVIEW_GENERAL_TABLE_SUCCESS :
            return {
                ...state,
                loading: false,
                userinputgeneraltable: action.payload


            }

        default:
            return state
    }


};