import { FISCAL_YEAR_SUCCESS } from "../../../Constant/ApaManagement/FiscalYear/FiscalYearConstant";

  

export const getFiscalYearReducer = (state = { fiscalYear: [] }, action) => {

    switch (action.type) {
        case FISCAL_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                fiscalYear: action.payload


            }

        default:
            return state
    }


};
