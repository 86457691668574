import { ACRONYM_TABLE_USER_INPUT_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/AcronymTable/AcronymUserInputConstant";



export const getUserInputPreviewAcronymTableReducer = (state = { userinputAcronymltable: [] }, action) => {

    switch (action.type) {
        case ACRONYM_TABLE_USER_INPUT_SUCCESS:
            return {
                ...state,
                loading: false,
                userinputAcronymltable: action.payload


            }

        default:
            return state
    }


};