import { APA_VERSION_SUCCESS } from "../../Constant/APADeterminationTimeSubmission/APAVersionConstan";

  

export const getAPAVersionReducer = (state = { apaVersion: [] }, action) => {

    switch (action.type) {
        case APA_VERSION_SUCCESS:
            return {
                ...state,
                loading: false,
                apaVersion: action.payload
            }

        default:
            return state
    }


};
