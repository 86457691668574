import { USER_INPUT_PREVIEW_WORKPLAN_TABLE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/WorkPlanTable/UserInputPreviewWorkPlanTableConstant";


export const getUserInputPreviewWorkPlanTableReducer = (state = { userinputworkplantable: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_PREVIEW_WORKPLAN_TABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                userinputworkplantable: action.payload


            }

        default:
            return state
    }


};