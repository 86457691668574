import { LINKTABLECOLUMNSALL_SUCCESS } from "../../Constant/ApaManagement/LinkTableColumnsAllConstant ";


export const getLinkTableColumnsAllReducer = (
  state = { linktablecolumnsall: [] },
  action
) => {
   
  switch (action.type) {
    case LINKTABLECOLUMNSALL_SUCCESS:
      return {
        ...state,
        loading: false,
        linktablecolumnsall: action.payload,
      };

    default:
      return state;
  }
};
