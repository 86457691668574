import { APA_ROLL_BACK_LIST_SUCCESS } from "../../../Constant/ApaManagement/MakerSubmission/ApaRollBackListConstant";


export const getApaRollBackListDataReducer = (state = { apaRollBackListData: [] }, action) => {

    switch (action.type) {
        case APA_ROLL_BACK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                apaRollBackListData: action.payload

            }

        default:
            return state
    }


};
