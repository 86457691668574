import { USER_INPUT_GET_INDICATOR_INPUT__ALL_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/WorkPlanTable/UserInputGetIndicatorInputAllConstant";



export const getUserInputIndicatorInputAllReducer = (state = { userinputindicatorAll: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_GET_INDICATOR_INPUT__ALL_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                userinputindicatorAll: action.payload


            }

        default:
            return state
    }


};
