import { USER_INPUT_PREVIEW_PREAMBLE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/Preamble/UserInputPreviewPreambleConstant";


export const getUserInputPreviewPreambleReducer = (state = { UserInputPreviewPreamble: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_PREVIEW_PREAMBLE_SUCCESS:
            return {
                ...state,
                loading: false,
                UserInputPreviewPreamble: action.payload


            }

        default:
            return state
    }


};