import { USER_INPUT_PREVIEW_GENERAL_SEC_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/GeneralTextBox/UserInputPreviewGeneralSecConstant";


export const getUserInputPreviewGeneralSecReducer = (state = { userinputgeneralsec: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_PREVIEW_GENERAL_SEC_SUCCESS:
            return {
                ...state,
                loading: false,
                userinputgeneralsec: action.payload


            }

        default:
            return state
    }


};