import { PREAMBLE_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/Preamble/PrembleConstant";
export const getPreambleReducer = (state = { preambleData: [] }, action) => {
    switch (action.type) {
        case PREAMBLE_TYPE_SUCCESS:
            return {
                ...state,
                preambleData: action.payload
            }



        default:
            return state;
    }
}




