import { HIERARCHY_WITH_OUT_AUTH_SUCCESS } from "../../Constant/WithOutAuthHierarchy/WithOutAuthHierarchyConstant";

export const getWithOutAuthHierarchyReducer = (state = { WithOutAuthHierarchyAll: [] }, action) => {
  switch (action.type) {
    case HIERARCHY_WITH_OUT_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        WithOutAuthHierarchyAll: action.payload,
      };

    default:
      return state;
  }
};
