import { ACTIVITY_CATEGORIES_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/Categories/ActivityCategories/ActivityCategoriesConstant";

 

export const getActivityCategoriesReducer = (state = { activityCategories: [] }, action) => {

    switch (action.type) {
        case ACTIVITY_CATEGORIES_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                activityCategories: action.payload


            }

        default:
            return state
    }


};
