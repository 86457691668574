import { DASHBOARD_ANALYTICS_SUCCESS } from "./DashBoardAnalyticsConstant";

 
export  const DashboardAnalyticsReducer = (state = { DashboardAnalyticsData: [] }, action) => {

    switch (action.type) {
        case DASHBOARD_ANALYTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                DashboardAnalyticsData: action.payload,
            }
        default:
            return state;
    }

}



