import { DASHBOARD_ANALYTICS_LIST_SUCCESS } from "./DashBoardAnalyticsListConstant";

 

export const DashboardAnalyticsListReducer = (state = { DashboardAnalyticsList: [] }, action) => {

    switch (action.type) {
        case DASHBOARD_ANALYTICS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                DashboardAnalyticsList: action.payload,
            }
        default:
            return state;
    }

}
