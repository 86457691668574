import {
    NOTICE_TYPE_FAIL,
    NOTICE_TYPE_REQUEST,
    NOTICE_TYPE_SUCCESS,
} from "../../Constant/Configuration/NoticeTypeConstant";

export const getNoticeReducer = (state = { noticeType: [] }, action) => {

    switch (action.type) {
        case NOTICE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                noticeType: action.payload


            }

        default:
            return state
    }


};
