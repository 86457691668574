import { SUB_MENU_TYPE_SUCCESS } from "./SubMenuConstant"; 

export const getAllSubMenuReducer = (state = { subMenuData: [] }, action) => {
  switch (action.type) {
    case SUB_MENU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenuData: action.payload,
      };

    default:
      return state;
  }
};
