import { USER_SUCCESS } from "../../../Constant/SubordinateOffice/User/UserConstant";

 

export const getUserReducer = (state = { userData: [] }, action) => {
    switch (action.type) {


        case USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload,
            }

        default:
            return state;



    }
}