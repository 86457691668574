import React from 'react';
import { Card, Col } from 'react-bootstrap';

const CommonLoading = () => {
    return (
        <div>
            <Col sm={12} lg={12} className='d-flex justify-content-center'>
                <div id="loaders5">
                        <div className="text-center">
                            <div className="lds-spinner">
                                <div></div>
                                <div></div> 
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                </div>
                    
            </Col>
        </div>
    );
};

export default CommonLoading;