
import {OVERALLPERFORMANCESALL_SUCCESS} from "../../Constant/ApaManagement/OverallPerformancesAllConstant ";


export const getOverallPerformancesAllReducer = (state = { overallperformanceall: [] }, action) => {

    switch (action.type) {
        case OVERALLPERFORMANCESALL_SUCCESS:
            return {
                ...state,
                loading: false,
                overallperformanceall: action.payload
            }

        default:
            return state
    }


};
