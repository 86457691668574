import { STRUCTURE_DROPDOWN_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/StructureDropdownData/StructureDropdownDataConstant";



export const getStructureDropdownReducer = (state = { DropdownData: [] }, action) => {

    switch (action.type) {
        case STRUCTURE_DROPDOWN_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                DropdownData: action.payload


            }

        default:
            return state
    }


};
