import { APA_ACHIEVEMENT_SUCCESS } from "../../Constant/ApaAchievement/ApaAchievementConstant";

  

export const getApaAchievementReducer = (state = { apaAchievement: [] }, action) => {

    switch (action.type) {
        case APA_ACHIEVEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                apaAchievement: action.payload
            }

        default:
            return state
    }


};
