import {   OVERALL_PERFORMANCE_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/OverAllPerformance/OverAllPerformanceConstant"


export const getOverAllPerformanceReducer =(state={overAllPerformace:[]},action)=>{
    switch (action.type) {
        case OVERALL_PERFORMANCE_TYPE_SUCCESS:
            return{
                ...state,
                overAllPerformace: action.payload
            }
            
           
    
        default:
             return state;
    }

}