
//Layer create

export const CREATE_LAYER_REQUEST = "CREATE_LAYER_REQUEST";
export const CREATE_LAYER_SUCCESS = "CREATE_LAYER_SUCCESS";
export const CREATE_LAYER_FAIL = "CREATE_LAYER_FAIL"
// Layer office get
export const LAYER_REQUEST = "LAYER_REQUEST";
export const LAYER_SUCCESS = "LAYER_SUCCESS";
export const LAYER_FAIL = "LAYER_FAIL"
// Layer office find
export const FIND_LAYER_REQUEST = "FIND_LAYER_REQUEST";
export const FIND_LAYER_SUCCESS = "FIND_LAYER_SUCCESS";
export const FIND_LAYER_FAIL = "FIND_LAYER_FAIL"
// Layer office update/put
export const UPDATE_LAYER_REQUEST = "UPDATE_LAYER_REQUEST";
export const UPDATE_LAYER_SUCCESS = "UPDATE_LAYER_SUCCESS";
export const UPDATE_LAYER_FAIL = "UPDATE_LAYER_FAIL"
// Layer office Delete update/put
export const DELETE_LAYER_REQUEST = "DELETE_LAYER_REQUEST";
export const DELETE_LAYER_SUCCESS = "DELETE_LAYER_SUCCESS";
export const DELETE_LAYER_FAIL = "DELETE_LAYER_FAIL"


//clear layer
export const CLEAR_LAYER_ERROR = "CLEAR_LAYER_ERROR "