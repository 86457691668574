import { OFFICE_SUCCESS } from "../../../Constant/SubordinateOffice/Office/OfficeConstant";


export const getOfficeReducer = (state = { officeData: [] }, action) => {
    switch (action.type) {


        case OFFICE_SUCCESS:
            return {
                ...state,
                loading: false,
                officeData: action.payload,
            }

        default:
            return state;



    }
}