import { NOTICE_TYPE_WITH_OUT_AUTH_SUCCESS } from "../../Constant/Configuration/NoticeTypeWithOutAuthConstant";

export const getNoticeTypeWithOutAuthReducer = (state = { noticeTypeWithOutAuth: [] }, action) => {

    switch (action.type) {
        case NOTICE_TYPE_WITH_OUT_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                noticeTypeWithOutAuth: action.payload


            }

        default:
            return state
    }


};
