import { STRUCTUREPREVIEWBYID_SUCCESS } from "../../../Constant/ApaManagement/StructurePreview/StructurepreviewbyIdConstant";


export const getStructurepreviewbyIdReducer = (
  state = { structurepreviewall: [] },
  action
) => {
   
  switch (action.type) {
    case STRUCTUREPREVIEWBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        structurepreviewall: action.payload,
      };

    default:
      return state;
  }
};
