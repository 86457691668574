import { INDICATOR_RECEIVER_ORG_SUCCESS } from "./IndicatorReceiverOrgListsConstant";

export const getIndicatorReceiverOrgReducer = (state = { indicatorReceiverOrgList: [] }, action) => {

switch(action.type){
    case INDICATOR_RECEIVER_ORG_SUCCESS:
        return{
            ...state,
            loading:false,
            indicatorReceiverOrgList:action.payload,
        }
    default:
        return state;
}

}



 