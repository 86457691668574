import { APA_CALENDER_SUCCESS } from "../../Constant/APACalender/APACalenderConstant";

  

export const getAPACalenderReducer = (state = { apaCalender: [] }, action) => {

    switch (action.type) {
        case APA_CALENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                apaCalender: action.payload
            }

        default:
            return state
    }


};
