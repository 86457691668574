import { FAQ_LANDING_REQUEST_SUCCESS } from "../../Constant/Configuration/FAQLandingConstan";


export const getFAQLandingReducer = (state = { faqLanding: [] }, action) => {

    switch (action.type) {
        case FAQ_LANDING_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                faqLanding: action.payload


            }

        default:
            return state
    }


};
