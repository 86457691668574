import { NOTIFICATIONS_DATA_SUCCESS } from "./NotificationsDataConstant";


export const NotificationsDataReducer = (state = { NotificationsData: [] }, action) => {

    switch (action.type) {
        case NOTIFICATIONS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                NotificationsData: action.payload,
            }
        default:
            return state;
    }

}



