import { USER_INPUT_GET_ACTIVITY_INPUT__ALL_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/WorkPlanTable/UserInputGetActivityInputAllConstant";



export const getUserInputActivityAllReducer = (state = { UserInputActivityAll: [] }, action) => {

    switch (action.type) {
        case USER_INPUT_GET_ACTIVITY_INPUT__ALL_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                UserInputActivityAll: action.payload


            }

        default:
            return state
    }


};
