import { MENU_TYPE_SUCCESS } from "./MenuConstant";

export const getAllMenuReducer = (state = { menuData: [] }, action) => {
  switch (action.type) {
    case MENU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuData: action.payload,
      };

    default:
      return state;
  }
};
