import { ROLE_TYPE_SUCCESS } from "../../Constant/Configuration/RoleConstant";

 

export const getRoleReducer = (state = { roleData: [] }, action) => {

    switch (action.type) {
        case ROLE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                roleData: action.payload


            }

        default:
            return state
    }


};
