import {
  OVERALL_PERFORMANCE_PREVIEW_SUCCESS,
} from "../../../../Constant/ApaManagement/ApaPreparationByUser/OverAllPerformance/OverAllPerformancePreviewConstant";


export const getOverAllPerformancePreviewReducer =(state={overallperformacepreview:[]},action)=>{
    switch (action.type) {
        case OVERALL_PERFORMANCE_PREVIEW_SUCCESS:
            return{
                ...state,
                overallperformacepreview: action.payload
            }
        default:
             return state;
    }

}