import { STATUS_REPORT_SUCCESS } from "./StatusReportDownloadConstant";

 
export const statusReportsReducer = (state = { statusReportData: {} }, action) => {
    switch (action.type) {
        case STATUS_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                statusReportData: action.payload,
            }
        default:
            return state;
    }

}



