import { APA_CALENDER_TABLE_SUCCESS } from "../../Constant/APACalender/APACalenderTableConstant";

  

export const getAPACalenderTableReducer = (state = { apaCalenderTable: [] }, action) => {

    switch (action.type) {
        case APA_CALENDER_TABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaCalenderTable: action.payload
            }

        default:
            return state
    }


};
