import { MESSAGE_OUTBOX_SUCCESS } from "../../../Constant/Message/MessageOutboxConstant";

export const getMessageOutboxReducer = (state = { messageOutbox: [] }, action) => {
  switch (action.type) {
    case MESSAGE_OUTBOX_SUCCESS:
      return {
        ...state,
        loading: false,
        messageOutbox: action.payload,
      };

    default:
      return state;
  }
};
