import { APA_ASSIGN_BY_CABINET_TYPE_SUCCESS } from "../../Constant/ApaManagement/ApaAssignByCabinetConstant";


export const getApaAssignDataReducer = (state = { apaAssignData: {} }, action) => {

    switch (action.type) {
        case APA_ASSIGN_BY_CABINET_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaAssignData: action.payload


            }

        default:
            return state
    }


};
