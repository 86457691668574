import {  OVERALL_PERFORMANCE_SUBTITLE_TYPE_SUCCESS } from "../../../../Constant/ApaManagement/ApaPreparationByUser/OverAllPerformance/OverAllPerformanceSubtitleConstant";


export const getOverAllSubtitleReducer = (state = { OverAllSubtitle: [] }, action) => {
    switch (action.type) {
        case OVERALL_PERFORMANCE_SUBTITLE_TYPE_SUCCESS:
            return {
                ...state,
                OverAllSubtitle: action.payload
            }



        default:
            return state;
    }

}