import { APA_GUIDE_LINE_SUCCESS } from "../../../Constant/ApaManagement/APAGuideLine/APAGuideLineConstant";

  

export const getAPAGuideLineReducer = (state = { apaGuideLine: [] }, action) => {

    switch (action.type) {
        case APA_GUIDE_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                apaGuideLine: action.payload


            }

        default:
            return state
    }


};
