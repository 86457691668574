import {
    DESIGNATION_SUCCESS,
} from "../../Constant/Configuration/DesignationConstant";


export const getDesignationReducer = (state = { designation: [] }, action) => {
    switch (action.type) {


        case DESIGNATION_SUCCESS:
            return {
                ...state,
                loading: false,
                designation: action.payload,
            }

        default:
            return state;



    }
}